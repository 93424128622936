function delayExecution(callback, delay) {
    setTimeout(callback, delay)
}

export const delay = (ms) => new Promise((res) => setTimeout(res, ms))

const apiBaseUrl = process.env.REACT_APP_API_URL_BASE

//   const msalConfig = {
//       auth: {
//           clientId: '70f0ff96-0505-4270-92df-76babba0f3d7'
//       }
//   };

//   const msalInstance = new PublicClientApplication(msalConfig);

//   var request = {
//     scopes: ["api://10db4c25-3f20-4719-b421-bb23cce19cd7/.default"]
//   };

//   let tokenResponse;
//   let objectId

//   const signInUser = async () => {
//     await msalInstance.initialize();
//     console.log('signing in',msalInstance)
//     try {
//       tokenResponse = await msalInstance.acquireTokenSilent(request);
//       if (!tokenResponse || !tokenResponse.accessToken || tokenResponse.accessToken.split('.').length !== 3) {
//         throw new Error('Invalid access token');
//       }
//       objectId = tokenResponse.account.idTokenClaims.oid;
//       console.log('User objectId:', objectId);
//     } catch (error) {
//       console.log('error not in interaction',error)
//       if (error) {
//         console.error('error in sign in', error)
//         // fallback to interaction when silent call fails
//         tokenResponse = await msalInstance.acquireTokenPopup(request);
//         if (!tokenResponse || !tokenResponse.accessToken || tokenResponse.accessToken.split('.').length !== 3) {
//           throw new Error('Invalid access token');
//         }
//         objectId = tokenResponse.account.idTokenClaims.oid;
//         console.log('User objectId: 2', objectId);
//       }
//       // handle other errors
//     }
//   };

export const uploadMultipleFilesUpdated = async (
    setLoading,
    files,
    filesContent,
    setError,
    container,
    orgAccessToken
) => {
    setLoading(true)

    try {
        const uploadPromises = files.map(async (file, i) => {
            const formData = new FormData()
            formData.append('file_name', file.name)
            formData.append('file_content', new Blob([filesContent[i]]))


            console.log('file', file)
            const magicNumbers = {
                pdf: '25504446', // PDF files start with "%PDF" (hex: 25 50 44 46)
                docx: '504b0304', // DOCX files start with "PK" (hex: 50 4B 03 04)
            };

            const getFileTypeFromBuffer = async (fileBuffer) => {
                // Convert the buffer to a Uint8Array
                const uint8Array = new Uint8Array(fileBuffer);
            
                // Extract the first 4 bytes and convert them to a hex string
                const magicNumber = uint8Array.slice(0, 4).reduce((acc, byte) => acc + byte.toString(16).padStart(2, '0'), '');
            
                // Compare the magic number to known values
                if (magicNumber === magicNumbers.pdf) {
                    return 'pdf';
                } else if (magicNumber === magicNumbers.docx) {
                    return 'docx';
                } else {
                    return 'unknown';
                }
            };

            const validateFileTypeFromBuffer = async (file) => {
                // Convert the file content to an ArrayBuffer
                const fileBuffer = await file.content;
            
                // Get the file type from the buffer
                const fileType = await getFileTypeFromBuffer(fileBuffer);
            
                if (fileType === 'unknown') {
                    console.error('Invalid file type. Only PDF and DOCX files are allowed.');
                    return false;
                }
            
                console.log(`File type is valid: ${fileType}`);
                return true;
            };



            validateFileTypeFromBuffer(file).then((isValid) => {
                if (isValid) {
                    console.log('File is valid for upload.');
                } else {
                    console.log('File is not valid for upload.');
                }
            });

            var headers = new Headers()
            headers.append(
                'Authorization',
                'Bearer ' + orgAccessToken.accessToken
            )
            headers.append('container', container)

            const requestOptions = {
                method: 'POST',
                body: formData,
                headers: headers,
            }

            const response = await fetch(
                `${apiBaseUrl}/api/uploadFileUpdated`,
                requestOptions
            )

            if (!response.ok) {
                throw new Error(
                    `Error uploading file ${file.name}: ${response.statusText}`
                )
            }

            return await response.text()
        })

        await Promise.all(uploadPromises)
        setLoading(false)
    } catch (error) {
        console.error('Error uploading files:', error)
        setError(true)
        setLoading(false)
    }
}

export const processInputDocument = async (fileName, orgAccessToken) => {
    const myHeaders = new Headers()
    myHeaders.append('Content-Type', 'application/json')
    myHeaders.append('Authorization', 'Bearer ' + orgAccessToken.accessToken)

    const raw = JSON.stringify({
        input_documents: [fileName],
    })

    const requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: raw,
        redirect: 'follow',
    }

    try {
        const response = await fetch(
            `${apiBaseUrl}/api/processInputDocument`,
            requestOptions
        )
        if (!response.ok) {
            throw new Error('Failed to process input document')
        }
        return await response.json()
    } catch (error) {
        console.error('Error processing input document:', error)
        throw error
    }
}

export const getCSVColumns = async (
    fileName,
    orgAccessToken,
    blob_container
) => {
    const myHeaders = new Headers()
    myHeaders.append('Content-Type', 'application/json')
    myHeaders.append('Authorization', 'Bearer ' + orgAccessToken.accessToken)

    const raw = JSON.stringify({
        csv_key: fileName,
        blob_container: blob_container,
    })

    const requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: raw,
        redirect: 'follow',
    }

    try {
        const response = await fetch(
            `${apiBaseUrl}/api/getCSVColumns`,
            requestOptions
        )
        if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`)
        }
        const data = await response.json()
        return data
    } catch (error) {
        console.error('Error getting CSV columns:', error)
        throw error
    }
}

export const preprocessUploadedCSV = async (
    csvKey,
    blobContainer,
    orgAccessToken
) => {
    const myHeaders = new Headers()
    myHeaders.append('Content-Type', 'application/json')
    myHeaders.append('Authorization', 'Bearer ' + orgAccessToken.accessToken)

    const raw = JSON.stringify({
        csv_key: csvKey,
        blob_container: blobContainer,
    })

    const requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: raw,
        redirect: 'follow',
    }

    try {
        const response = await fetch(
            `${apiBaseUrl}/api/preprocessUploadedCSV`,
            requestOptions
        )
        if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`)
        }
        const result = response
        return result
    } catch (error) {
        console.error('Error preprocessing CSV:', error)
        throw error
    }
}

export const addTabularDataToDoc = async (tableData, orgAccessToken) => {
    const myHeaders = new Headers()
    myHeaders.append('Content-Type', 'application/json')
    myHeaders.append('Authorization', 'Bearer ' + orgAccessToken.accessToken)

    const requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: JSON.stringify(tableData),
        redirect: 'follow',
    }

    try {
        const response = await fetch(
            `${apiBaseUrl}/api/addTabularDataToDoc`,
            requestOptions
        )
        if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`)
        }
        // NOTE: no JSON response; do NOT parse expecting one.
        const result = await response.text()
        return result
    } catch (error) {
        console.error('Error adding tabular data to document:', error)
        throw error
    }
}

export const getTableTitlesInSection = async (payload, orgAccessToken) => {
    const myHeaders = new Headers()
    myHeaders.append('Content-Type', 'application/json')
    myHeaders.append('Authorization', 'Bearer ' + orgAccessToken.accessToken)

    const requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: JSON.stringify(payload),
        redirect: 'follow',
    }

    try {
        const response = await fetch(
            `${apiBaseUrl}/api/getTableTitlesInSection`,
            requestOptions
        )
        if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`)
        }
        const result = await response.json()
        return result
    } catch (error) {
        console.error('Error getting table titles:', error)
        throw error
    }
}

export const updateTabularDataToDoc = async (payload, orgAccessToken) => {
    const myHeaders = new Headers()
    myHeaders.append('Content-Type', 'application/json')
    myHeaders.append('Authorization', 'Bearer ' + orgAccessToken.accessToken)

    const requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: JSON.stringify(payload),
        redirect: 'follow',
    }

    try {
        const response = await fetch(
            `${apiBaseUrl}/api/updateTabularDataToDoc`,
            requestOptions
        )
        if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`)
        }
        const result = await response.text()
        return result
    } catch (error) {
        console.error('Error updating tabular data in document:', error)
        throw error
    }
}

export const generateOutline = async (
    setLoading,
    setError,
    files,
    outline,
    document_name,
    product_name,
    document_type,
    orgAccessToken
) => {
    setLoading(true)
    console.log('files', files)
    console.log('outline', outline)

    const myHeaders = new Headers()
    myHeaders.append('Content-Type', 'application/json')
    myHeaders.append('Authorization', 'Bearer ' + orgAccessToken.accessToken)

    const raw = JSON.stringify({
        input_path: files,
        input_container: 'input',
        outline: outline,
        product_name: product_name,
        output_doc_type: document_type,
        output_doc_name: document_name,
        example: '',
    })

    const requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: raw,
        redirect: 'follow',
    }

    await fetch(`${apiBaseUrl}/api/generateOutline`, requestOptions)
        .then((response) => response.text())
        .then((result) => console.log(result))
        .catch((error) => {
            setError(true)
            console.error(error)
        })
}

export const generateBoth = async (
    setLoading,
    setError,
    document_name,
    product_name,
    document_type,
    orgAccessToken
) => {
    setLoading(true)
    console.log('document_name', document_name)
    console.log('product_name', product_name)
    console.log('document_type', document_type)

    const myHeaders = new Headers()
    myHeaders.append('Content-Type', 'application/json')
    myHeaders.append('Authorization', 'Bearer ' + orgAccessToken.accessToken)

    const raw = JSON.stringify({
        document_name: document_name,
        product_name: product_name,
        document_type: document_type,
    })

    const requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: raw,
        redirect: 'follow',
    }

    await fetch(`${apiBaseUrl}/api/generateBoth`, requestOptions)
        .then((response) => response.text())
        .then((result) => console.log(result))
        .catch((error) => {
            console.error(error)
            setError(true)
        })
}

export const createINDModule2Section = async (
    setLoading,
    setError,
    files,
    document_name,
    product_name,
    orgAccessToken
) => {
    setLoading(true)

    const myHeaders = new Headers()
    myHeaders.append('Content-Type', 'application/json')
    myHeaders.append('Authorization', 'Bearer ' + orgAccessToken.accessToken)

    const raw = JSON.stringify({
        input_path: files,
        conditions: '',
        external_sources: [''],
        product_name: product_name,
        input_container: 'intermediate',
        randomID: 'testnamespace7',
        pc_index_name: 'biohaven',
        source: '',
        query: '',
        doc_name: document_name,
        output_doc_name: document_name,
    })

    const requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: raw,
        redirect: 'follow',
    }

    try {
        const response = await fetch(
            `${apiBaseUrl}/api/createINDModule2Section`,
            requestOptions
        )
        const result = await response.text()
        setLoading(false)
        return result // Return the result here
    } catch (error) {
        console.error(error)
        setError(true)
        setLoading(false)
        throw error // Rethrow the error after logging and setting error state
    }
}

export const getDocumentSets = async (setLoading, setError, orgAccessToken) => {
    setLoading(true)

    const myHeaders = new Headers()
    myHeaders.append('Authorization', 'Bearer ' + orgAccessToken.accessToken)

    const requestOptions = {
        method: 'GET',
        redirect: 'follow',
        headers: myHeaders,
    }

    try {
        const response = await fetch(
            `${apiBaseUrl}/api/getAllDocumentSets`,
            requestOptions
        )
        const result = await response.json()
        setLoading(false)
        return result // Return the result here
    } catch (error) {
        console.error(error)
        // setError(true);
        setLoading(false)
        throw error // Rethrow the error after logging and setting error state
    }
}

export const getOneDocumentSet = async (
    setLoading,
    setError,
    id,
    orgAccessToken
) => {
    setLoading(true)

    const myHeaders = new Headers()
    myHeaders.append('Content-Type', 'application/json')
    myHeaders.append('Authorization', 'Bearer ' + orgAccessToken.accessToken)

    console.log('id in getOneDocumentSet', id)

    const raw = JSON.stringify({
        document_set: id,
    })

    const requestOptions = {
        method: 'POST',
        redirect: 'follow',
        headers: myHeaders,
        body: raw,
    }

    try {
        const response = await fetch(
            `${apiBaseUrl}/api/getOneDocumentSet`,
            requestOptions
        )
        const result = await response.json()
        setLoading(false)
        return result // Return the result here
    } catch (error) {
        console.error(error)
        setError(true)
        setLoading(false)
        throw error // Rethrow the error after logging and setting error state
    }
}

export const removeFileExtension = async (filename) => {
    const commonExtensions = [
        '.csv',
        '.xlsx',
        '.docx',
        '.pdf',
        '.rtf',
        '.txt',
        '.doc',
        '.xls',
        '.ppt',
        '.pptx',
        '.odt',
        '.ods',
        '.odp',
    ]
    let cleanedFilename = filename

    for (const ext of commonExtensions) {
        if (cleanedFilename.toLowerCase().endsWith(ext)) {
            cleanedFilename = cleanedFilename.slice(0, -ext.length)
            break // Stop after removing one extension
        }
    }

    return cleanedFilename
}

export const getIDFromName = async (documentName, orgAccessToken) => {
    const cleanedDocumentName = await removeFileExtension(documentName)

    const myHeaders = new Headers()
    myHeaders.append('Content-Type', 'application/json')
    myHeaders.append('Authorization', 'Bearer ' + orgAccessToken.accessToken)

    const raw = JSON.stringify({
        document_name: cleanedDocumentName,
    })

    const requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: raw,
        redirect: 'follow',
    }

    try {
        const response = await fetch(
            `${apiBaseUrl}/api/getIDFromName`,
            requestOptions
        )
        if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`)
        }
        const result = await response.json()
        return result.id // Assuming the API returns an object with an 'id' field
    } catch (error) {
        console.error('Error getting document ID:', error)
        throw error
    }
}

export const getOneDocument = async (
    setLoading,
    setError,
    id,
    orgAccessToken
) => {
    setLoading(true)

    const myHeaders = new Headers()
    myHeaders.append('Content-Type', 'application/json')
    myHeaders.append('Authorization', 'Bearer ' + orgAccessToken.accessToken)

    console.log('id in getOneDocument', id)

    const raw = JSON.stringify({
        document_id: id,
    })

    const requestOptions = {
        method: 'POST',
        redirect: 'follow',
        headers: myHeaders,
        body: raw,
    }

    try {
        const response = await fetch(
            `${apiBaseUrl}/api/getOneDocument`,
            requestOptions
        )
        const result = await response.json()
        setLoading(false)
        return result // Return the result here
    } catch (error) {
        console.error(error)
        setError(true)
        setLoading(false)
        throw error // Rethrow the error after logging and setting error state
    }
}

export const saveFile = async (id, fileContent, orgAccessToken) => {
    // Create a new URL object
    console.log('id', id)
    // // Split the filename on '.docx', take the first part, and add '.docx' back to it
    // fileName = fileName.split('.docx')[0] + '.docx';

    // console.log('newFilename',fileName); // Outputs: 'b66ec74293ad4f1587a8110a6d006317-device-protocol-template-output.docx'

    console.log('filesContent', fileContent)

    try {
        // Use Promise.all to upload multiple files concurrently

        // console.log('file here', fileName)
        const formData = new FormData()
        formData.append('id', id)
        formData.append('file_content', fileContent)

        var headers = new Headers()
        headers.append('Authorization', 'Bearer ' + orgAccessToken.accessToken)
        // headers.append("Authorization", "Bearer " + tokenResponse.accessToken);
        // headers.append("X-MS-CLIENT-PRINCIPAL-ID", '050e6292-662f-4b0f-9a89-4b0beca8bed6')

        const requestOptions = {
            method: 'POST',
            body: formData,
            headers: headers,
        }

        const response = await fetch(
            `${apiBaseUrl}/api/saveFile`,
            requestOptions
        )

        if (!response.ok) {
            throw new Error(`Error uploading file: ${response.statusText}`)
        }

        const result = await response.text()
        console.log('file name:', result)
        createOutputVectorstore(id, 'output', orgAccessToken)

        // Handle the response from the Azure Function if needed
    } catch (error) {
        console.error('Error uploading files:', error)
    }
}

export const extractAndStoreMultiple = async (
    setLoading,
    setError,
    files,
    inputContainer,
    orgAccessToken
) => {
    setLoading(true)

    console.log('extracting and storing')

    var myHeaders = new Headers()
    myHeaders.append('Authorization', 'Bearer ' + orgAccessToken.accessToken)
    // myHeaders.append("X-MS-CLIENT-PRINCIPAL-ID", "050e6292-662f-4b0f-9a89-4b0beca8bed6" );
    // myHeaders.append("Authorization", "Bearer " + tokenResponse.accessToken);

    var raw = JSON.stringify({
        input_path: files,
        // "pc_index_name": indexName,
        input_container: inputContainer,
        doc_type: 'testing',
        // "randomID": namespace
    })

    var requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: raw,
        redirect: 'follow',
    }

    await fetch(`${apiBaseUrl}/api/extractAndStoreMultiple`, requestOptions)
        .then((response) => response.text())
        .then((result) => {
            console.log(result)
            setLoading(false)
        })
        .catch((error) => {
            console.log('error', error)
            setError(true)
            setLoading(false)
        })
}

export const extractAndStoreMultipleMongo = async (
    setLoading,
    setError,
    files,
    inputContainer,
    orgAccessToken
) => {
    setLoading(true)

    console.log('extracting and storing in MongoDB')

    var myHeaders = new Headers()
    myHeaders.append('Authorization', 'Bearer ' + orgAccessToken.accessToken)

    var raw = JSON.stringify({
        input_path: files,
        input_container: inputContainer,
        doc_type: 'testing',
    })

    var requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: raw,
        redirect: 'follow',
    }

    try {
        const response = await fetch(
            `${apiBaseUrl}/api/extractAndStoreMultipleMongo`,
            requestOptions
        )
        const result = await response.text()
        console.log(result)
    } catch (error) {
        console.log('error', error)
        setError(true)
    } finally {
        setLoading(false)
    }
}

export const getSources = async (
    setLoading,
    setError,
    document_id,
    section_text,
    section_name,
    orgAccessToken
) => {
    setLoading(true)

    const myHeaders = new Headers()
    myHeaders.append('Content-Type', 'application/json')
    myHeaders.append('Authorization', 'Bearer ' + orgAccessToken.accessToken)

    const raw = JSON.stringify({
        document_id: document_id,
        section_name: '',
        section_text: section_text,
    })

    const requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: raw,
        redirect: 'follow',
    }

    try {
        const response = await fetch(
            `${apiBaseUrl}/api/getSources`,
            requestOptions
        )
        const result = await response.json() // Await the parsing of the JSON
        console.log(result)
        return result // Return the parsed JSON
    } catch (error) {
        console.error(error)
        setError(error) // Assuming setError is used to handle errors
        return null // Return null or an appropriate value indicating failure
    }
}

export const findInconsistencies = async (
    document_id,
    section,
    orgAccessToken
) => {
    const myHeaders = new Headers()
    myHeaders.append('Content-Type', 'application/json')
    myHeaders.append('Authorization', 'Bearer ' + orgAccessToken.accessToken)

    const raw = JSON.stringify({
        document_id: document_id,
        section: section,
        input_container: 'output',
    })

    const requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: raw,
        redirect: 'follow',
    }

    try {
        const response = await fetch(
            `${apiBaseUrl}/api/findInconsistencies`,
            requestOptions
        )
        const result = await response.json() // Await the parsing of the JSON
        console.log(result)
        return result // Return the parsed JSON
    } catch (error) {
        console.error(error)
        return null // Return null or an appropriate value indicating failure
    }
}

export const chatWithDoc = async (
    question,
    document_id,
    chatHistory,
    orgAccessToken
) => {
    // console.log('chatting with', protocolFile)
    // const url_part = protocolFile.replace("https://", "")
    // console.log('url_part', url_part)
    // const fileName = protocolFile.split("/").pop()
    // console.log('fileNam in chatwithbot', fileName)

    var myHeaders = new Headers()
    myHeaders.append('Content-Type', 'application/json')
    myHeaders.append('Authorization', 'Bearer ' + orgAccessToken.accessToken)
    // myHeaders.append("Authorization", "Bearer " + tokenResponse.accessToken);

    var raw = JSON.stringify({
        // "input_path": protocolFile,
        document_id: document_id,
        question: question,
        chat_history: chatHistory,
    })

    var requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: raw,
        redirect: 'follow',
    }

    return (
        fetch(`${apiBaseUrl}/api/chatwithInputDoc`, requestOptions)
            .then((response) => response.text())
            // .then(result => console.log(result))
            .catch((error) => console.log('error', error))
    )
    // await delay(2000);
}

export const createOutputVectorstore = async (
    document_id,
    container_name,
    orgAccessToken
) => {
    const myHeaders = new Headers()
    myHeaders.append('Content-Type', 'application/json')
    myHeaders.append('Authorization', 'Bearer ' + orgAccessToken.accessToken)

    console.log('creating output vector store!')

    const raw = JSON.stringify({
        document_id: document_id,
        // input_container: container_name,
        input_container: 'output',
        doc_type: 'testing',
    })

    const requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: raw,
        redirect: 'follow',
    }

    await fetch(`${apiBaseUrl}/api/createOutputVectorstore`, requestOptions)
        .then((response) => response.text())
        .then((result) => console.log(result))
        .catch((error) => console.error(error))
}

// export const handleMultipleFiles = (files, fileContents, setMultipleFileList, setMultipleFileNameList, setMultipleFileContentList) => {
//     try {
//       // Handle saving the file content or any other logic as needed for each file
//       const newFiles = [];
//       console.log('files here', files)

//       for (let i = 0; i < files.length; i++) {
//         const file = files[i];
//         const content = fileContents[i];
//         newFiles.push({ name: file.name, content });
//         console.log('newFiles', newFiles)
//       }

//       // Update React state with the array of file objects
//       setMultipleFileList(newFiles);
//       setMultipleFileNameList(newFiles.map((file) => file.name));
//       setMultipleFileContentList(fileContents);
//     } catch (e) {
//       console.log('Error in handling files', e);
//     }
//   };

export const regenerateSection = async (
    document_id,
    sections,
    additional_info,
    files,
    orgAccessToken
) => {
    const myHeaders = new Headers()
    myHeaders.append('Content-Type', 'application/json')
    myHeaders.append('Authorization', 'Bearer ' + orgAccessToken.accessToken)

    const raw = JSON.stringify({
        document_id: document_id,
        sections: sections,
        additional_info: additional_info,
        input_path: files,
    })

    const requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: raw,
        redirect: 'follow',
    }

    await fetch(`${apiBaseUrl}/api/regenerateSection`, requestOptions)
        .then((response) => response.text())
        .then((result) => console.log(result))
        .catch((error) => console.error(error))
}

export const createNewDocumentSet = async (documentSet, orgAccessToken) => {
    const myHeaders = new Headers()
    myHeaders.append('Content-Type', 'application/json')
    myHeaders.append('Authorization', 'Bearer ' + orgAccessToken.accessToken)

    const raw = JSON.stringify({
        document_set_name: documentSet,
    })

    const requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: raw,
        redirect: 'follow',
    }

    try {
        const response = await fetch(
            `${apiBaseUrl}/api/createNewDocumentSet`,
            requestOptions
        )
        const result = await response.text()
        console.log(result)

        return result // Return the result here
    } catch (error) {
        console.error(error)
        throw error // Rethrow the error after logging and setting error state
    }
}

export const listAllFiles = async (container_name, orgAccessToken) => {
    const myHeaders = new Headers()
    myHeaders.append('Authorization', 'Bearer ' + orgAccessToken.accessToken)

    const raw = JSON.stringify({
        container_name: container_name,
    })

    const requestOptions = {
        method: 'POST',
        redirect: 'follow',
        body: raw,
        headers: myHeaders,
    }

    try {
        const response = await fetch(
            `${apiBaseUrl}/api/listAllBlobs`,
            requestOptions
        )
        if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`)
        }
        const result = await response.json() // Assuming the server returns JSON. Use .text() if it's plain text
        return result // This will be the value of all blobs
    } catch (error) {
        console.error(error)
        throw error // Rethrow to allow caller to handle
    }
}

export const handleMultipleFiles = (files, fileContents) => {
    try {
        const newFiles = []
        const fileNameList = []
        const fileContentList = []

        for (let i = 0; i < files.length; i++) {
            const file = files[i]
            const content = fileContents[i]
            newFiles.push({ name: file.name, content })
            fileNameList.push(file.name)
            fileContentList.push(content)
        }

        // Return an object containing the three lists
        return {
            multipleFileList: newFiles,
            multipleFileNameList: fileNameList,
            multipleFileContentList: fileContentList,
        }
    } catch (e) {
        console.log('Error in handling files', e)
        // Return null or an empty object to indicate failure
        return null
    }
}

export const processTemplate = async (
    setLoading,
    setError,
    templateName,
    documentType,
    orgAccessToken
) => {
    setLoading(true)

    const myHeaders = new Headers()
    myHeaders.append('Content-Type', 'application/json')
    myHeaders.append('Authorization', 'Bearer ' + orgAccessToken.accessToken)

    const raw = JSON.stringify({
        template_name: templateName,
        document_type: documentType,
    })

    const requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: raw,
        redirect: 'follow',
    }

    try {
        const response = await fetch(
            `${apiBaseUrl}/api/processTemplate`,
            requestOptions
        )
        if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`)
        }
        const result = await response.text() // Assuming the server returns JSON. Use .text() if it's plain text
        return result // This will be the value of all blobs
    } catch (error) {
        console.error(error)
        throw error // Rethrow to allow caller to handle
    }
}

export const combinedCreateNewDocument = async (
    files,
    document_name,
    product_name,
    document_type,
    outline,
    document_set,
    orgAccessToken,
    rules,
    extract
) => {
    const myHeaders = new Headers()
    myHeaders.append('Content-Type', 'application/json')
    myHeaders.append('Authorization', 'Bearer ' + orgAccessToken.accessToken)

    const raw = JSON.stringify({
        input_path: files,
        input_container: 'input',
        output_doc_name: document_name,
        output_doc_type: document_type,
        document_set: document_set,
        product_name: product_name,
        outline: outline,
        example: '',
        example_document: '',
        extract: extract,
        input_documents: files,
        user_rules: rules,
        model_name: 'Claude-3.5-Sonnett',
    })

    const requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: raw,
        redirect: 'follow',
    }

    try {
        const response = await fetch(
            `${apiBaseUrl}/api/startCombinedProcess`,
            requestOptions
        )
        if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`)
        }
        const result = await response.json()

        return result
    } catch (error) {
        console.error('Error creating new document:', error)
        throw error
    }
}

export const getTemplateTypes = async (orgAccessToken) => {
    const myHeaders = new Headers()
    myHeaders.append('Authorization', 'Bearer ' + orgAccessToken.accessToken)

    const requestOptions = {
        method: 'GET',
        redirect: 'follow',
        headers: myHeaders,
    }

    try {
        const response = await fetch(
            `${apiBaseUrl}/api/getTemplateTypes`,
            requestOptions
        )
        if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`)
        }
        const result = await response.json() // Assuming the server returns JSON. Use .text() if it's plain text
        return result // This will be the value of all blobs
    } catch (error) {
        console.error(error)
        throw error // Rethrow to allow caller to handle
    }
}

export const getOutlineFromTemplates = async (templateType, orgAccessToken) => {
    console.log('templateType', templateType)

    const myHeaders = new Headers()
    myHeaders.append('Content-Type', 'application/json')
    myHeaders.append('Authorization', 'Bearer ' + orgAccessToken.accessToken)

    const raw = JSON.stringify({
        template_name: templateType,
    })

    const requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: raw,
        redirect: 'follow',
    }

    try {
        const response = await fetch(
            `${apiBaseUrl}/api/getOutlineFromTemplate`,
            requestOptions
        )
        if (!response.ok) {
            console.log(response)
            throw new Error(`HTTP error! status: ${response.status}`)
        }
        const result = await response.text()

        const sections = result
            .split('\n')
            .filter((section) => section.trim() !== '')

        const outlineSections = sections.map((section) => ({
            sectionName: section.trim(),
            active: true,
        }))

        return outlineSections
    } catch (error) {
        console.error('Error fetching outline from template:', error)
        throw error
    }
}

export const getOutlineFromSource = async (documentId, orgAccessToken) => {
    const myHeaders = new Headers()
    myHeaders.append('Content-Type', 'application/json')
    myHeaders.append('Authorization', 'Bearer ' + orgAccessToken.accessToken)

    const raw = JSON.stringify({
        document_id: documentId,
    })

    const requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: raw,
        redirect: 'follow',
    }

    try {
        const response = await fetch(
            `${apiBaseUrl}/api/getOneDocument`,
            requestOptions
        )
        if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`)
        }
        const result = await response.json()
        const sections = result
            .split('\n')
            .filter((section) => section.trim() !== '')

        const outlineSections = sections.map((section) => ({
            sectionName: section.trim(),
            active: true,
        }))
        return outlineSections
    } catch (error) {
        console.error('Error fetching document outline:', error)
        throw error
    }
}

export const listAllTemplates = async (orgAccessToken) => {
    const myHeaders = new Headers()
    myHeaders.append('Authorization', 'Bearer ' + orgAccessToken.accessToken)

    const requestOptions = {
        method: 'GET',
        redirect: 'follow',
        headers: myHeaders,
    }

    try {
        const response = await fetch(
            `${apiBaseUrl}/api/listAllTemplates`,
            requestOptions
        )
        if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`)
        }
        const result = await response.json() // Assuming the server returns JSON. Use .text() if it's plain text
        return result // This will be the value of all blobs
    } catch (error) {
        console.error(error)
        throw error // Rethrow to allow caller to handle
    }
}

export const uploadEditedFile = async (
    setLoading,
    files,
    filesContent,
    setError,
    id,
    orgAccessToken
) => {
    setLoading(true)
    const myHeaders = new Headers()
    myHeaders.append('Authorization', 'Bearer ' + orgAccessToken.accessToken)

    console.log('filesContent', filesContent)

    try {
        // Use Promise.all to upload multiple files concurrently
        const uploadPromises = files.map(async (file, i) => {
            console.log('file here', filesContent[i])
            const formData = new FormData()
            formData.append('file_name', file.name)
            formData.append('file_content', new Blob([filesContent[i]]))

            var headers = new Headers()
            headers.append('id', id)
            // headers.append("Authorization", "Bearer " + tokenResponse.accessToken);

            // headers.append('X-MS-CLIENT-PRINCIPAL-ID', '050e6292-662f-4b0f-9a89-4b0beca8bed6')

            const requestOptions = {
                method: 'POST',
                body: formData,
                headers: headers,
            }

            const response = await fetch(
                `${apiBaseUrl}/api/uploadEditedDocument`,
                requestOptions
            )

            if (!response.ok) {
                throw new Error(
                    `Error uploading file ${file.name}: ${response.statusText}`
                )
            }

            const result = await response.text()

            console.log('file name:', result)
        })

        await Promise.all(uploadPromises)
        // await delay(2000);

        setLoading(false)

        // Handle the response from the Azure Function if needed
    } catch (error) {
        console.error('Error uploading files:', error)
        setError(true)
        setLoading(false)
    }
}

export const generateAbbreviations = async (document_id, orgAccessToken) => {
    console.log('generating abbreviations')

    const myHeaders = new Headers()
    myHeaders.append('Content-Type', 'application/json')
    myHeaders.append('Authorization', 'Bearer ' + orgAccessToken.accessToken)

    const raw = JSON.stringify({
        document_id: document_id,
    })

    const requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: raw,
        redirect: 'follow',
    }

    try {
        const response = await fetch(
            `${apiBaseUrl}/api/generate_abbreviations`,
            requestOptions
        )
        const result = await response.text()
        return result
    } catch (error) {
        console.error(error)
    }
}

export const createMod26PKTable = async (
    input_path,
    input_container,
    template_document_type,
    document_name
) => {
    console.log('creating mod26pk table')

    console.log('Request Body:', {
        files: input_path,
        document_name: document_name,
        input_container: input_container,
        template_document_type: template_document_type,
    })

    const myHeaders = new Headers()
    myHeaders.append('Content-Type', 'application/json')

    const raw = JSON.stringify({
        input_path: input_path,
        input_container: input_container,
        template_name: template_document_type,
        document_name: document_name,
    })

    const requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: raw,
        redirect: 'follow',
    }

    fetch(`${apiBaseUrl}/api/combinedMod26PKTable`, requestOptions)
        .then((response) => response.text())
        .then((result) => console.log(result))
        .catch((error) => console.error(error))
}

export const emailTrigger = async () => {
    const requestOptions = {
        method: 'GET',
        redirect: 'follow',
    }

    fetch(`${apiBaseUrl}/api/emailTrigger`, requestOptions)
        .then((response) => response.text())
        .then((result) => console.log(result))
        .catch((error) => console.error(error))
}

export const getOneTemplate = async (document_type, orgAccessToken) => {
    const myHeaders = new Headers()
    myHeaders.append('Content-Type', 'application/json')
    myHeaders.append('Authorization', 'Bearer ' + orgAccessToken.accessToken)

    const raw = JSON.stringify({
        document_type: document_type,
    })

    const requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: raw,
        redirect: 'follow',
    }

    try {
        const response = await fetch(
            `${apiBaseUrl}/api/getOneTemplate`,
            requestOptions
        )
        const result = await response.json()
        return result // Return the result here
    } catch (error) {
        console.error(error)
        throw error // Rethrow the error after logging and setting error state
    }
}

export const reCreateINDModule2Section = async (payload, orgAccessToken) => {
    const myHeaders = new Headers()
    myHeaders.append('Content-Type', 'application/json')
    myHeaders.append('Authorization', 'Bearer ' + orgAccessToken.accessToken)

    const requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: JSON.stringify(payload),
        redirect: 'follow',
    }

    try {
        const response = await fetch(
            `${apiBaseUrl}/api/reCreateINDModule2Section`,
            requestOptions
        )
        if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`)
        }
        const result = await response.text()
        console.log(result)
        return result
    } catch (error) {
        console.error('Error recreating section:', error)
        throw error
    }
}

export const reCreateINDModule2Selection = async (payload, orgAccessToken) => {
    const myHeaders = new Headers()
    myHeaders.append('Content-Type', 'application/json')
    myHeaders.append('Authorization', 'Bearer ' + orgAccessToken.accessToken)

    const requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: JSON.stringify(payload),
        redirect: 'follow',
    }

    try {
        const response = await fetch(
            `${apiBaseUrl}/api/reCreateINDModule2Selection`,
            requestOptions
        )
        if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`)
        }
        const result = await response.text()
        return result
    } catch (error) {
        console.error('Error recreating section:', error)
        throw error
    }
}
export const savePrompts = async (
    setLoading,
    setError,
    documentId,
    sectionInfo,
    orgAccessToken
) => {
    // setLoading(true)

    const myHeaders = new Headers()
    myHeaders.append('Content-Type', 'application/json')
    myHeaders.append('Authorization', 'Bearer ' + orgAccessToken.accessToken)

    console.log('sectionInfo in savePrompts', sectionInfo)

    const raw = JSON.stringify({
        document_id: documentId,
        section_info: sectionInfo,
    })

    const requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: raw,
        redirect: 'follow',
    }

    try {
        const response = await fetch(
            `${apiBaseUrl}/api/savePrompts`,
            requestOptions
        )
        if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`)
        }
        const result = await response.text()
        console.log('saved prompts', result)
        return result
    } catch (error) {
        console.error(error)
        setError(true)
        throw error
    } finally {
        // setLoading(false)
    }
}

export const getHardCodedDoc = async (docType, orgAccessToken) => {
    const myHeaders = new Headers()
    myHeaders.append('Content-Type', 'application/json')
    myHeaders.append('Authorization', 'Bearer ' + orgAccessToken.accessToken)

    const raw = JSON.stringify({
        document_type: docType,
    })

    const requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: raw,
        redirect: 'follow',
    }

    try {
        const response = await fetch(
            `${apiBaseUrl}/api/getHardCodedDocs`,
            requestOptions
        )
        if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`)
        }
        const result = await response.text()
        return result
    } catch (error) {
        console.error('Error fetching hard-coded document:', error)
        return null
    }
}

export const getStoredTableTitles = async (
    doc_id,
    // table_type,
    // population,
    // return_multiple,
    // organization,
    orgAccessToken
) => {
    const myHeaders = new Headers()
    myHeaders.append('Content-Type', 'application/json')
    myHeaders.append('Authorization', 'Bearer ' + orgAccessToken.accessToken)

    const raw = JSON.stringify({
        // database_name: 'generalMainData',
        // container_name: 'tables',
        document_id: doc_id,
        // table_type_value: table_type,
        // population_value: population,
        // return_multiple: return_multiple,
        // organization: organization,
    })

    const requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: raw,
        redirect: 'follow',
    }

    try {
        const response = await fetch(
            `${apiBaseUrl}/api/getStoredTableTitles`,
            requestOptions
        )
        if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`)
        }
        const result = await response.json()
        return result
    } catch (error) {
        console.error('Error fetching stored table titles:', error)
        throw error
    }
}

export const extractAndStoreTFLTables = async (
    csvKey,
    csvContainer,
    csvOutputContainer,
    documentType,
    documentId,
    orgAccessToken
) => {
    const myHeaders = new Headers()
    myHeaders.append('Content-Type', 'application/json')
    myHeaders.append('Authorization', 'Bearer ' + orgAccessToken.accessToken)

    const raw = JSON.stringify({
        csv_key: csvKey,
        csv_container: csvContainer,
        csv_output_container: csvOutputContainer,
        document_type: documentType,
        document_id: documentId,
    })

    const requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: raw,
        redirect: 'follow',
    }

    try {
        const response = await fetch(
            `${apiBaseUrl}/api/extractAndStoreTFLTables`,
            requestOptions
        )
        if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`)
        }
        return await response.text()
    } catch (error) {
        console.error('Error extracting and storing TFL tables:', error)
        throw error
    }
}

export const getAnalytesFromDocument = async (documentList, orgAccessToken) => {
    console.log('getting analytes from document')

    const myHeaders = new Headers()
    myHeaders.append('Content-Type', 'application/json')
    myHeaders.append('Authorization', 'Bearer ' + orgAccessToken.accessToken)

    const raw = JSON.stringify({
        doc_name: documentList[0], // Assuming we're only handling one document for now
    })

    const requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: raw,
        redirect: 'follow',
    }

    try {
        const response = await fetch(
            `${apiBaseUrl}/api/getAnalytesFromDocument`,
            requestOptions
        )
        if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`)
        }
        const result = await response.json()
        return result
    } catch (error) {
        console.error('Error fetching analytes:', error)
        throw error
    }
}

export const generate265Tables = async (payload, orgAccessToken) => {
    const myHeaders = new Headers()
    myHeaders.append('Content-Type', 'application/json')
    myHeaders.append('Authorization', 'Bearer ' + orgAccessToken.accessToken)

    console.log('Payload:', payload)

    const requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: JSON.stringify(payload),
        redirect: 'follow',
    }

    try {
        const response = await fetch(
            `${apiBaseUrl}/api/generateMod265FullPkTables`,
            requestOptions
        )
        if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`)
        }
        const result = await response.text()
        console.log(result)
        return result
    } catch (error) {
        console.error('Error generating 2.6.5 tables:', error)
        throw error
    }
}

export const extractAndVectorizePKTables = async (file, orgAccessToken) => {
    const myHeaders = new Headers()
    myHeaders.append('Content-Type', 'application/json')
    myHeaders.append('Authorization', 'Bearer ' + orgAccessToken.accessToken)

    const raw = JSON.stringify({
        input_path: [file],
        input_container: 'input',
    })

    const requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: raw,
        redirect: 'follow',
    }

    try {
        const response = await fetch(
            `${apiBaseUrl}/api/extractAndVectorizePKTables`,
            requestOptions
        )
        if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`)
        }
        const result = await response.json()
        const statusQueryGetUri = result.statusQueryGetUri

        console.log(`Status Query URI: ${statusQueryGetUri}`)

        // Polling function to check the status
        const checkStatus = async () => {
            return new Promise((resolve, reject) => {
                const poll = async () => {
                    try {
                        const statusResponse = await fetch(statusQueryGetUri)
                        const statusResult = await statusResponse.json()

                        if (statusResult.runtimeStatus === 'Completed') {
                            console.log('Function completed:', statusResult)
                            resolve(statusResult)
                        } else if (statusResult.runtimeStatus === 'Failed') {
                            reject(
                                new Error(
                                    'Function failed: ' +
                                        JSON.stringify(statusResult)
                                )
                            )
                        } else {
                            console.log(
                                'Function not completed yet. Checking again in 1 minute...'
                            )
                            setTimeout(poll, 60000) // Check again in 1 minute
                        }
                    } catch (error) {
                        reject(error)
                    }
                }

                poll()
            })
        }

        // Start polling and wait for completion
        const statusQueryResult = await checkStatus()
        return statusQueryResult
    } catch (error) {
        console.error('Error:', error)
        throw error
    }
}

export const uploadPKFile = async (file, orgId, orgAccessToken) => {
    const myHeaders = new Headers()
    myHeaders.append('Authorization', 'Bearer ' + orgAccessToken.accessToken)

    const formData = new FormData()
    formData.append('file', file)

    // Include orgId in the filename
    const orgSpecificFileName = `${orgId}/${file.name}`
    formData.append('file_name', orgSpecificFileName)

    const requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: formData,
        redirect: 'follow',
    }

    try {
        const response = await fetch(
            `${apiBaseUrl}/api/uploadPKFile`,
            requestOptions
        )

        if (!response.ok) {
            throw new Error(`Error uploading file: ${response.statusText}`)
        }

        const result = await response.text()
        console.log('File uploaded successfully:', result)
        return orgSpecificFileName // Return the filename used in blob storage
    } catch (error) {
        console.error('Error uploading PK file:', error)
        throw error
    }
}

export const saveRuleset = async (ruleset, orgAccessToken) => {
    const myHeaders = new Headers()
    myHeaders.append('Content-Type', 'application/json')
    myHeaders.append('Authorization', 'Bearer ' + orgAccessToken.accessToken)

    const raw = JSON.stringify({
        ruleset: ruleset,
    })

    const requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: raw,
        redirect: 'follow',
    }

    try {
        const response = await fetch(
            `${apiBaseUrl}/api/saveRuleset`,
            requestOptions
        )
        if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`)
        }
        const result = await response.text()
        return result
    } catch (error) {
        console.error('Error saving ruleset:', error)
        throw error
    }
}

export const getAllRulesets = async (orgAccessToken) => {
    const myHeaders = new Headers()
    myHeaders.append('Authorization', 'Bearer ' + orgAccessToken.accessToken)

    const requestOptions = {
        method: 'GET',
        redirect: 'follow',
        headers: myHeaders,
    }

    try {
        const response = await fetch(
            `${apiBaseUrl}/api/getAllRulesets`,
            requestOptions
        )
        if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`)
        }
        const result = await response.json()
        return result
    } catch (error) {
        console.error('Error fetching rulesets:', error)
        throw error
    }
}

export const extractContentPlan = (documentName, orgAccessToken) => {
    const myHeaders = new Headers()
    myHeaders.append('Content-Type', 'application/json')
    myHeaders.append('Authorization', 'Bearer ' + orgAccessToken.accessToken)

    const raw = JSON.stringify({
        content_plan_key: documentName,
        input_container: 'intermediate',
    })

    const requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: raw,
        redirect: 'follow',
    }

    return fetch(`${apiBaseUrl}/api/extractContentPlan`, requestOptions)
        .then((response) => response.json())
        .catch((error) =>
            console.error('Error extracting content plan:', error)
        )
}

export const revertVersion = async (documentId, orgAccessToken) => {

    const myHeaders = new Headers()
    myHeaders.append('Content-Type', 'application/json')
    myHeaders.append('Authorization', 'Bearer ' + orgAccessToken.accessToken)

    const raw = JSON.stringify({
        document_id: documentId,
        container_name: 'output',
    })

    const requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: raw,
        redirect: 'follow',
    }

    return fetch(`${apiBaseUrl}/api/revertVersions`, requestOptions)
        .then((response) => response.json())
        .catch((error) =>
            console.error('Error reverting section:', error)
        )


}

export const getJWTToken = async (orgAccessToken, payload) => {
    const myHeaders = new Headers();
    myHeaders.append('Authorization', 'Bearer ' + orgAccessToken.accessToken);
    myHeaders.append('Content-Type', 'application/json');

    const raw = JSON.stringify({
        payload: payload
    });

    const requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: raw,
        redirect: 'follow',
    };

    try {
        const response = await fetch(`${apiBaseUrl}/api/generateJWT`, requestOptions);
        if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
        }
        const result = await response.json();
        return result;
    } catch (error) {
        console.error('Error fetching JWT token:', error);
        throw error;
    }
};
